import {
    getPageProfile,
    postPageProfile,
    putPageProfile,
    deletePageProfile,
} from "@services/page-profile";
import deepClone from "deep-clone";
import { getRHUsers } from "@services/account/user";

const state = {
    allProfile: [],
    selectedProfile: { id: 0 },
    updated: false,
    loading: false,
    isBtnDisabled: true,
    defaultSetting: [
        "fast-trade",
        "same-direction",
        "big-lot",
        "trade-on-credit",
        "deposit-withdrawal",
        "large-volume-usd",
        "profit-taker",
    ],
    triggered: false,
    listRHUser: [],
};

const actions = {
    getProfileAction({ commit }, data) {
        commit("UPDATE_BTN_STATUS", true);
        getPageProfile(data).then((res) => {
            if (res.data.length == 0) {
                commit("UPDATE_ALL_PROFILE", [
                    {
                        id: 0,
                        text: "default",
                        selected: state.defaultSetting,
                        book: [],
                        server: [],
                    },
                ]);
                commit("UPDATE_SELECTED_PROFILE", {
                    id: 0,
                    text: "default",
                    selected: state.defaultSetting,
                    book: [],
                    server: [],
                });
            } else {
                const returnedProfileList = res.data.map((profile) => {
                    return {
                        id: profile.id,
                        text: profile.profile_name,
                        selected: JSON.parse(profile.value).selected,
                        book: JSON.parse(profile.value).book,
                        server: JSON.parse(profile.value).server,
                    };
                });
                commit("UPDATE_ALL_PROFILE", returnedProfileList);

                const alertHubId = parseInt(localStorage.getItem("alertHubId"));
                if (alertHubId) {
                    if (
                        returnedProfileList.find(
                            (item) => item.id === alertHubId
                        )
                    ) {
                        commit(
                            "UPDATE_SELECTED_PROFILE",
                            returnedProfileList.find(
                                (item) => item.id === alertHubId
                            )
                        );
                    } else {
                        commit(
                            "UPDATE_SELECTED_PROFILE",
                            returnedProfileList[0]
                        );
                    }
                } else {
                    commit("UPDATE_SELECTED_PROFILE", returnedProfileList[0]);
                }
            }
        });
    },
    putProfileAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        putPageProfile(data).then(() => {
            commit("CHANGE_UPDATE_STATUS", true);
            commit("UPDATE_LOADING", false);
        });
    },
    postProfileAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        postPageProfile(data).then(() => {
            commit("CHANGE_UPDATE_STATUS", true);
            commit("UPDATE_LOADING", false);
        });
    },
    deleteProfileAction({ commit, state }, data) {
        deletePageProfile(data).then((res) => {
            if (res.status === 200) {
                const remaining = state.allProfile.filter(
                    (profile) => profile.id != data.id
                );
                if (remaining.length > 0) {
                    commit("UPDATE_ALL_PROFILE", remaining);
                    commit("UPDATE_SELECTED_PROFILE", remaining[0]);
                } else {
                    commit("UPDATE_ALL_PROFILE", [
                        {
                            id: 0,
                            text: "default",
                            selected: state.defaultSetting,
                            book: [],
                            server: [],
                        },
                    ]);
                    commit("UPDATE_SELECTED_PROFILE", {
                        id: 0,
                        text: "default",
                        selected: state.defaultSetting,
                        book: [],
                        server: [],
                    });
                }
            }
        });
    },
    updateTriggerStatus({ state }, data) {
        state.triggered = data;
    },
    getRHUsersAction({ commit }) {
        getRHUsers().then((res) => {
            const userList = res.data;
            commit("UPDATE_LIST_USER", userList);
        });
    },
};

const mutations = {
    UPDATE_SELECTED_PROFILE(state, data) {
        if (localStorage.getItem("alertHubId") !== data.id && data.id >= 0) {
            localStorage.setItem("alertHubId", data.id);
        }
        state.selectedProfile = deepClone(data);
    },
    UPDATE_ALL_PROFILE(state, data) {
        localStorage.setItem("alertHubProfiles", JSON.stringify(data));
        state.allProfile = data;
        state.isBtnDisabled = false;
    },
    CHANGE_UPDATE_STATUS(state, data) {
        state.updated = data;
    },
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_BTN_STATUS(state, data) {
        state.isBtnDisabled = data;
    },
    UPDATE_LIST_USER(state, data) {
        state.listRHUser = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};

<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                small
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                x-small
                :disabled="isBtnDisabled"
                style="display: block; float: right"
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="white"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            style="margin-right: 5px"
                            small
                        >
                            mdi-information
                        </v-icon>
                    </template>
                    <span>Customize alert hub page elements.</span>
                </v-tooltip>
                {{ selectedProfile.text }}
            </v-btn>
        </template>
        <v-list v-model="menu" dense>
            <div
                v-for="(item, index) in allProfile"
                :key="index"
                v-show="allProfile[0].id != 0"
            >
                <v-list-item>
                    <v-list-item-content
                        @click="changeSelectedProfile(item.text)"
                    >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider />
            </div>
            <v-dialog v-model="editingDialog" width="1200" :persistent="true">
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                        dense
                        color="red lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        v-show="allProfile[0].id != 0"
                        @click="assignEditingProfile"
                    >
                        <v-list-item-icon style="margin-right: 0">
                            <v-icon small>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                        dense
                        color="red lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="assignAddingProfile"
                    >
                        <v-list-item-icon style="margin-right: 0">
                            <v-icon small>mdi-plus-thick</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Add</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>

                <v-card :loading="loading" id="editDialog">
                    <v-app-bar
                        flat
                        color="rgba(0, 0, 0, 0)"
                        style="background: rgb(26, 57, 88)"
                    >
                        <v-toolbar-title class="text-h6 white--text pl-0">{{
                            isEditing ? "Edit Profile" : "Add Profile"
                        }}</v-toolbar-title>
                    </v-app-bar>

                    <v-card-text style="padding-top: 20px; padding-bottom: 0">
                        <v-row style="margin-bottom: 1px">
                            <v-col v-if="isEditing" cols="6"
                                ><v-select
                                    dense
                                    v-model="editingProfile"
                                    :items="allProfile"
                                    item-text="text"
                                    item-value="id"
                                    label="Profile name"
                                    hide-details
                                ></v-select
                            ></v-col>
                            <v-col cols="6"
                                ><v-text-field
                                    dense
                                    label="New name"
                                    :placeholder="dialogNamePlaceholder"
                                    v-model="newName"
                                    hide-details
                                ></v-text-field
                            ></v-col>
                        </v-row>

                        <v-alert
                            dense
                            outlined
                            type="info"
                            style="margin-top: 2px"
                        >
                            Toggle to select alert types you would like to show
                            in the data table.
                        </v-alert>

                        <v-card style="padding: 10px">
                            <v-row>
                                <v-col
                                    cols="6"
                                    style="padding-top: 0; padding-left: 0"
                                >
                                    <v-select
                                        label="Server Filter"
                                        :items="serverOptions"
                                        item-text="server_name"
                                        item-value="id"
                                        multiple
                                        v-model="editingProfile.server"
                                        hide-details
                                    ></v-select>
                                </v-col>

                                <v-col
                                    cols="6"
                                    style="padding-top: 0; padding-left: 0"
                                >
                                    <v-select
                                        label="Book Filter"
                                        :items="bookOptions"
                                        item-text="book_name"
                                        item-value="book_name"
                                        multiple
                                        v-model="editingProfile.book"
                                        hide-details
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="3"
                                    v-for="columnName in defaultSetting"
                                    :key="columnName"
                                >
                                    <v-row>
                                        <v-switch
                                            hide-details
                                            dense
                                            style="
                                                margin-top: 0px;
                                                margin-bottom: 6px;
                                            "
                                            v-model="editingProfile.selected"
                                            :label="
                                                capitalizeKey(
                                                    columnName,
                                                    '-',
                                                    ' '
                                                )
                                            "
                                            :value="columnName"
                                        ></v-switch>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="grey" text @click="editingDialog = false">
                            Cancel
                        </v-btn>

                        <template>
                            <v-menu
                                v-model="confirmMenu"
                                absolute
                                :position-x="x"
                                :position-y="y"
                                persistent
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-show="isEditing"
                                        v-on="on"
                                        color="red"
                                        text
                                        :disabled="editingProfile == null"
                                        @click="onDelete"
                                        :loading="loading"
                                    >
                                        Delete
                                    </v-btn>
                                </template>

                                <v-card width="450">
                                    <v-card-title style="color: orange">
                                        Are you sure you want to delete this
                                        profile?
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            @click="confirmMenu = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="confirmDeletion"
                                        >
                                            Confirm
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </template>

                        <v-btn
                            color="green"
                            text
                            @click="updateProfile"
                            :loading="loading"
                        >
                            {{ isEditing ? "Update" : "Add" }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-list>
    </v-menu>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
import { helper } from "@components/mixins/helper";
import validator, { rules } from "@assets/js/validator";
import deepClone from "deep-clone";
import { getServer } from "@services/server";
import { getBooksPk } from "@services/books";

export default {
    mixins: [snackbar, helper],
    computed: {
        ...mapState("AlertHub", [
            "allProfile",
            "selectedProfile",
            "updated",
            "isBtnDisabled",
            "loading",
            "defaultSetting",
        ]),
        dialogNamePlaceholder() {
            return this.isEditing
                ? "Enter a new name if needed."
                : "Please enter a profile name";
        },
    },
    data() {
        return {
            editingDialog: null,
            menu: false,
            isEditing: true,
            editingProfile: {
                settings: {},
            },
            newName: null,
            confirmMenu: false,
            x: 800,
            y: 300,
            rules: rules,
            bookOptions: [],
            serverOptions: [],
        };
    },
    watch: {
        editingProfile(nv) {
            if (nv != null && this.allProfile) {
                let newValue = null;
                if (typeof nv === "number") {
                    newValue = this.allProfile.filter(
                        (profile) => profile.id == nv
                    )[0];
                    this.editingProfile = newValue;
                }
            }
        },
        allProfile(nv) {
            if (nv[0].id == 0) {
                this.editingDialog = false;
            }
        },
        updated(nv, ov) {
            if (nv) {
                this.editingDialog = false;
                this.getProfileAction({ meta_value: "alert-hub" });
                this.CHANGE_UPDATE_STATUS(false);
            }
        },
    },
    methods: {
        ...mapActions("AlertHub", [
            "getProfileAction",
            "putProfileAction",
            "postProfileAction",
            "deleteProfileAction",
        ]),
        ...mapMutations("AlertHub", [
            "UPDATE_SELECTED_PROFILE",
            "CHANGE_UPDATE_STATUS",
            "UPDATE_BTN_STATUS",
            "UPDATE_ALL_PROFILE",
        ]),
        changeSelectedProfile(profileName) {
            this.UPDATE_SELECTED_PROFILE(
                deepClone(
                    this.allProfile.filter(
                        (profile) => profile.text == profileName
                    )[0]
                )
            );
        },
        onDelete() {
            this.confirmMenu = false;
            //dialog x position + half of the dialog width minus half of the confirm dialog width
            this.x =
                document.getElementById("editDialog").getBoundingClientRect()
                    .x + 375;
            this.y =
                document.getElementById("editDialog").getBoundingClientRect()
                    .y + 125;
            this.$nextTick(() => {
                this.confirmMenu = true;
            });
        },
        assignEditingProfile() {
            this.newName = null;
            this.isEditing = true;
            this.editingProfile = null;
            this.editingProfile = this.selectedProfile;
            this.editingDialog = true;
        },
        assignAddingProfile() {
            this.newName = null;
            this.isEditing = false;
            this.editingProfile = {
                selected: deepClone(this.defaultSetting),
                book: this.bookOptions.map((item) => item.book_name),
                server: this.serverOptions.map((item) => item.id),
            };
            this.editingDialog = true;
        },
        updateProfile() {
            const params = { value: {} };
            //editing
            if (this.isEditing) {
                params.id = this.editingProfile.id;

                if (
                    this.newName == null ||
                    this.newName == this.editingProfile.text ||
                    this.newName == ""
                ) {
                    params.profile_name = this.editingProfile.text;
                } else {
                    this.newName = validator.cleanUpStringData(this.newName);
                    if (
                        this.allProfile.filter(
                            (profile) => profile.text == this.newName
                        ).length != 0 ||
                        this.newName.trim() == ""
                    ) {
                        this.snackBarDanger(
                            "The new profile name is invalid, please use another one."
                        );
                        return;
                    } else {
                        params.profile_name = this.newName;
                    }
                }
                params.value = {
                    selected: this.editingProfile.selected,
                    book: this.editingProfile.book,
                    server: this.editingProfile.server,
                };
                this.putProfileAction(params);
            }
            //creating
            else {
                params.meta_value = "alert-hub";
                if (!validator.commonStringValidator(this.newName)) {
                    this.snackBarDanger(
                        "The profile name is invalid, please enter a new name."
                    );
                    return;
                }
                this.newName = validator.cleanUpStringData(this.newName);
                if (
                    this.allProfile.filter(
                        (profile) => profile.text == this.newName
                    ).length != 0
                ) {
                    this.snackBarDanger(
                        "The profile name already exist, please enter a new name."
                    );
                    return;
                } else {
                    params.profile_name = this.newName;
                }
                params.value.selected = this.editingProfile.selected;
                params.value.book = this.editingProfile.book;
                params.value.server = this.editingProfile.server;
                this.postProfileAction(params);
            }
        },
        confirmDeletion() {
            const params = { id: this.editingProfile.id };
            this.deleteProfileAction(params);
            this.editingProfile = this.selectedProfile;
        },
        getServerOptions() {
            getServer().then((res) => {
                this.serverOptions = res.data;
            });
        },
        getBookOptions() {
            getBooksPk().then((res) => {
                this.bookOptions = res.data;
            });
        },
    },
    created() {
        this.getServerOptions();
        this.getBookOptions();
    },
    mounted() {
        this.CHANGE_UPDATE_STATUS(false);
        const profileList = localStorage.getItem("alertHubProfiles");
        if (profileList) {
            this.UPDATE_BTN_STATUS(true);
            const recordedAll = JSON.parse(profileList);
            this.UPDATE_ALL_PROFILE(recordedAll);
            const alertHubId = parseInt(localStorage.getItem("alertHubId"));
            if (alertHubId) {
                if (recordedAll.find((item) => item.id === alertHubId)) {
                    this.UPDATE_SELECTED_PROFILE(
                        recordedAll.find((item) => item.id === alertHubId)
                    );
                } else {
                    this.UPDATE_SELECTED_PROFILE(recordedAll[0]);
                }
            } else {
                this.UPDATE_SELECTED_PROFILE(recordedAll[0]);
            }
        } else {
            this.getProfileAction({ meta_value: "alert-hub" });
        }
    },
    destroyed() {
        this.UPDATE_SELECTED_PROFILE({
            id: -1,
            text: "",
            selected: [],
        });
    },
};
</script>

<style>
::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
.v-input--selection-controls .v-input__slot .v-label {
    font-weight: 800;
}
</style>

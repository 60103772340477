import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../Layout.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: Layout,
        children: [
            {
                path: "",
                name: "Home",
                component: () => import("../views/Home.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Book Monitor", disabled: true, href: "/" }
                    ],
                    permission: "book"
                }
            },
            {
                path: "lp",
                name: "LP",
                component: () => import("../views/lp/lp.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [{ text: "LP", disabled: true, href: "/" }],
                    permission: "lp"
                }
            },
            {
                path: "/search",
                name: "SearchHome",
                component: () => import("../views/search/Search.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Search", disabled: true, href: "/#/search" }
                    ],
                    permission: "search"
                }
            },
            {
                path: "/k8s/monitor",
                name: "MonitorK8S",
                component: () => import("../views/k8s/index.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Montor K8S", disabled: true, href: "/#/" }
                    ],
                    permission: ["k8s", "index"]
                }
            },
            {
                path: "/copy-trade",
                name: "CopyTrade",
                component: () => import("../views/copy-trade/index.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Copy Trade", disabled: true, href: "/#/copy-trade" }
                    ],
                    permission: ["copyTrade", "index"]
                }
            },
            {
                path: "/alert-hub",
                name: "AlertHub",
                component: () => import("../views/alert-hub/index.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "AlertHub", disabled: true, href: "/#/alert-hub" }
                    ],
                    permission: ["alertHub", "index"]
                }
            },
            {
                path: "/ib-Monitor",
                name: "IBMonitor",
                component: () => import("../views/ib-monitor/index.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "IB Monitor", disabled: true, href: "/#/ib-monitor" }
                    ],
                    permission: ["ibMonitor", "index"]
                }
            }
        ]
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    },
    {
        path: "/alert",
        name: "Alert",
        component: Layout,
        children: [
            {
                path: "v2",
                name: "AlertOverviewV2",
                component: () => import("../views/alert/OverviewV2.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Alert", disabled: true, href: "/#/" },
                        {
                            text: "Overview",
                            disabled: false,
                            href: "/#/alert/v2"
                        }
                    ],
                    permission: ["alert", "overview"]
                }
             },
            {
                path: "mobile",
                name: "AlertOverviewMobile",
                component: () => import("../views/alert/OverviewMobile.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Alert", disabled: true, href: "/#/" },
                        {
                            text: "Overview",
                            disabled: false,
                            href: "/#/alert/mobile"
                        }
                    ],
                    permission: ["alert", "overview"]
                }
            }
        ]
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    },
    {
        path: "/system",
        component: Layout,
        children: [
            {
                path: "settings",
                name: "Settings",
                component: () => import("../views/settings/Settings.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "System", disabled: true, href: "/#/" },
                        {
                            text: "Settings",
                            disabled: true,
                            href: "/#/system/settings"
                        }
                    ],
                    permission: "settings"
                }
            }
        ]
    },
    {
        path: "/account",
        name: "Account",
        component: Layout,
        children: [
            {
                path: "permission",
                name: "AccountPermission",
                component: () => import("../views/account/Permission.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Account", disabled: true, href: "/#/" },
                        {
                            text: "Permission",
                            disabled: true,
                            href: "/#/account/permission"
                        }
                    ],
                    permission: ["account", "permission"]
                }
            },
            {
                path: "management",
                name: "AccountManagement",
                component: () => import("../views/account/Management.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Account", disabled: true, href: "/#/" },
                        {
                            text: "Account Management",
                            disabled: true,
                            href: "/account/management"
                        }
                    ],
                    permission: ["account", "management"]
                }
            },
            {
                path: "security",
                name: "AccountSecurity",
                component: () => import("../views/account/Security.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Account", disabled: true, href: "/#/" },
                        {
                            text: "Security",
                            disabled: true,
                            href: "/#/account/security"
                        }
                    ],
                    permission: ["account", "profile"]
                }
            }
        ]
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    },
    {
        path: "/signin",
        name: "Signin",
        component: () => import("../views/account/Signin.vue")
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    },
    {
        path: "/forget-password",
        name: "ForgetPassword",
        component: () => import("../views/account/ForgetPassword.vue")
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import("../views/account/ResetPassword.vue")
    }
];

const router = new VueRouter({
    routes
});

router.onError(error => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});

export default router;

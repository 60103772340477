import {
    getPermission,
    postPermission,
    putPermission,
    deletePermission,
} from "@services/account/permission";
import store from "@store/store";

const state = {
    permissionHeader: [
        { text: "Id", value: "id", align: "left" },
        { text: "Permission Name", value: "permission_name", align: "left" },
        { text: "Permission", value: "permission", align: " d-none" },
        { text: "Created At", value: "created_at", align: "left" },
        { text: "Updated At", value: "updated_at", align: "left" },
        { text: "Actions", value: "action", align: "left" },
    ],
    permissionData: [],
    loading: false,
    loadJson: true,
    permissionSettingInfo: {
        homeLoginDetailSummary: {
            label: "Login detail summary",
            tooltip:
                "Choose to enable click function on user login ID to display corresponding summary.",
        },
        ibLoginDetailSummary: {
            label: "Login detail summary",
            tooltip:
                "Choose to enable click function on user login ID to display corresponding summary in IB page.",
        },
        extraSymbolDetails: {
            label: "Download symbol details",
            tooltip:
                "Choose to allow symbol details to be downloaded at the time of book monitor data download",
        },
        allowDownloadAll: {
            label: "Allow Download All",
            tooltip:
                "Choose to show button which allows to download all book snapshots or symbol snapshot at one click.",
        },
        ibExtraSymbolDetails: {
            label: "Download symbol details",
            tooltip:
                "Choose to allow symbol details to be downloaded at the time of IB monitor data download",
        },
        dailySummaryIcon: {
            label: "Daily summary",
            tooltip: "Choose to show daily summary icon in the header",
        },
        mtdVolumeColumn: {
            label: "Mtd volume column",
            tooltip:
                "Choose to show MTD Vol column of ALL Books table in the book monitor page",
        },
        mtdPnlColumn: {
            label: "Mtd pnl column",
            tooltip:
                "Choose to show MTD PNL column of ALL Books table in the book monitor page",
        },
        loginSummaryInfo: {
            label: "Login Information",
            tooltip: "Choose to show Info Tab in login summary dialog",
        },
        ibLoginSummaryInfo: {
            label: "Login Information",
            tooltip:
                "Choose to show Info Tab in login summary dialog located in IB page.",
        },
        loginSummaryStatistic: {
            label: "Login Statistic",
            tooltip:
                "Choose to show Statistic chart in Info Tab within login summary dialog",
        },
        ibLoginSummaryStatistic: {
            label: "Login Statistic",
            tooltip:
                "Choose to show Statistic chart in Info Tab within login summary dialog located in IB page.",
        },
        loginSummaryTrades: {
            label: "Open/Close Trades",
            tooltip: "Choose to show Trades Tab in login summary dialog",
        },
        devSumUp: {
            label: "Dev Sum Up",
            tooltip: "Choose to show sum up icon in symbol detail dialog",
        },
        symbolSnapshot: {
            label: "Symbol Snapshot",
            tooltip: "Choose to show snapshot tab in symbol info dialog",
        },
        loginSnapshot: {
            label: "Login Snapshot",
            tooltip: "Choose to show snapshot tab in login summary dialog",
        },
        ibLoginSummaryTrades: {
            label: "Open/Close Trades",
            tooltip:
                "Choose to show Trades Tab in login summary dialog located in IB page.",
        },
        enableLP: {
            label: "Enable",
            tooltip: "Choose to display LP tab in the top menu bar",
        },
        enableSearch: {
            label: "Enable",
            tooltip: "Choose to display Search tab in the top menu bar",
        },
        allowDownloadRequest: {
            label: "Allow Download",
            tooltip:
                "Choose to allow download requests and check download progress",
        },
        ibRelatedFieldsSearch: {
            label: "Show IB Related Fields",
            tooltip:
                "Choose to enable IB related filter and table headers in search page",
        },
        ibRelatedFieldsAlert: {
            label: "Show IB Related Fields",
            tooltip: "Choose to show IB related table headers in alert page",
        },
        enableAlert: {
            label: "Enable",
            tooltip: "Choose to display Alert tab in the top menu bar",
        },
        allowTickMutation: {
            label: "Allow Tick Mutation",
            tooltip:
                "Choose to allow users with such permission to mute tick alerts.",
        },
        alertConfig: {
            label: "Alert config",
            tooltip: "Choose to show alert config section in the Settings page",
        },
        brokerConfig: {
            label: "Broker config",
            tooltip:
                "Choose to show broker config section in the Settings page",
        },
        serverConfig: {
            label: "Server config",
            tooltip:
                "Choose to show server config section in the Settings page",
        },
        bookConfig: {
            label: "Book config",
            tooltip: "Choose to show book config section in the Settings page",
        },
        routingConfig: {
            label: "Routing config",
            tooltip:
                "Choose to show routing config section in the Settings page",
        },
        copyTradeConfig: {
            label: "Copy Trade config",
            tooltip:
                "Choose to show copy trade config section in the Settings page",
        },
        alertConfigEdit: {
            label: "Alert config edit",
            tooltip:
                "Choose to enable the function to edit alert configurations",
        },
        lpConfig: {
            label: "Lp config",
            tooltip: "Choose to show lp config section in the setting page",
        },
        notificationConfig: {
            label: "Notification config",
            tooltip:
                "Choose to show notification config section in the setting page",
        },
        accountPermission: {
            label: "Permission",
            tooltip: "Choose to show permission tab in the account menu",
        },
        accountManagement: {
            label: "Management",
            tooltip: "Choose to show management tab in the account menu",
        },
        accountConfig: {
            label: "Settings",
            tooltip: "Choose to show settings tab in the account menu",
        },
        accountProfile: {
            label: "Security",
            tooltip: "Choose to show security tab in the account menu",
        },
        enableK8s: {
            label: "Enable",
            tooltip: "Choose to display K8S tab in the top menu bar",
        },
        enableCopyTrade: {
            label: "Enable",
            tooltip: "Choose to display Copy Trade tab in the top menu bar",
        },
        enableAlertHub: {
            label: "Enable",
            tooltip: "Choose to display Alert Hub tab in the top menu bar",
        },
        enableIBMonitor: {
            label: "Enable",
            tooltip: "Choose to display IB Monitor tab in the top menu bar",
        },
        pkTaker: {
            label: "PK Taker Channel",
            tooltip:
                "Choose to display pk taker Symbol Price Channel section in the Settings page.",
        },
    },
};

const actions = {
    /**
     * Fetch configuration data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     * @return  {[type]}          [return description]
     */
    getPermissionAction({ commit }) {
        commit("UPDATE_LOADING", true);
        commit("UPDATE_LOAD_JSON", false);
        getPermission().then((res) => {
            if (res.status === 200) {
                store.state.snackcolor = "green";
                store.state.snackbar = true;
                store.state.snackmsg = "Load permission successfully";
            }
            const data = res.data;
            commit("GET_CONFIG_DATA", data);
            commit("UPDATE_LOADING", false);
            commit("UPDATE_LOAD_JSON", true);
        });
    },
    /**
     * Update permission
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    putPermissionAction({ commit, dispatch }, params) {
        commit("UPDATE_LOADING", true);
        if (
            state.permissionData.find((item) => item.id === params.id)
                .permission_name === params.permission_name
        ) {
            putPermission(params).then((res) => {
                store.state.snackcolor = "green";
                store.state.snackbar = true;
                store.state.snackmsg = res.data.msg;
                commit("UPDATE_LOADING", false);
                dispatch("getPermissionAction");
            });
        } else {
            if (
                state.permissionData
                    .map((item) => item.permission_name)
                    .includes(params.permission_name)
            ) {
                store.state.snackcolor = "red";
                store.state.snackbar = true;
                store.state.snackmsg = "Permission already exist";
                commit("UPDATE_LOADING", false);
            } else {
                putPermission(params).then((res) => {
                    store.state.snackcolor = "green";
                    store.state.snackbar = true;
                    store.state.snackmsg = res.data.msg;
                    commit("UPDATE_LOADING", false);
                    dispatch("getPermissionAction");
                });
            }
        }
    },
    /**
     * Create new permission
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    postPermissionAction({ commit, dispatch }, params) {
        commit("UPDATE_LOADING", true);

        if (
            state.permissionData
                .map((item) => item.permission_name)
                .includes(params.permission_name)
        ) {
            store.state.snackcolor = "red";
            store.state.snackbar = true;
            store.state.snackmsg = "Permission already exist";
            commit("UPDATE_LOADING", false);
        } else {
            postPermission(params).then((res) => {
                store.state.snackcolor = "green";
                store.state.snackbar = true;
                store.state.snackmsg = res.data.msg;
                commit("UPDATE_LOADING", false);
                dispatch("getPermissionAction");
            });
        }
    },
    deletePermissionAction({ commit, dispatch }, params) {
        commit("UPDATE_LOADING", true);
        deletePermission(params)
            .then((res) => {
                commit("UPDATE_LOADING", false);
                store.state.snackcolor = "green";
                store.state.snackbar = true;
                store.state.snackmsg = res.data.msg;
                dispatch("getPermissionAction");
            })
            .catch(({ response }) => {
                store.state.snackcolor = "red";
                store.state.snackbar = true;
                store.state.snackmsg =
                    "Error " + response.status + ": " + response.data.msg;
                commit("UPDATE_LOADING", false);
            });
    },
};

const mutations = {
    /**
     * Update loading status
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    /**
     * Update load json
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOAD_JSON(state, data) {
        state.loadJson = data;
    },
    /**
     * Assign permission data after api request
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    GET_CONFIG_DATA(state, data) {
        state.permissionData = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
